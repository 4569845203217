import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Documentation from './Documentation'; // Adjust the path as necessary
import TopRow from './TopRow';
import './TopRow.css';
import SplashScreen from './SplashScreen';
import LogPopup from './LogPopup';
import Modal from './Modal.js'
import Interactive from './Interactive.js'

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function App() {
  const baseUrl = 'https://rnascape.usc.edu'
  // const baseUrl = 'http://10.136.114.14'
  //const baseUrl = 'http://10.136.113.92'

  const [showSplash, setShowSplash] = useState(true); // State to control SplashScreen
  const [counter, setCounter] = useState(0); // Counter for regenerating graph
  const [sumRotation, setSumRotation] = useState(0); // New state for rotation
  const [sumRotation2, setSumRotation2] = useState(0); // New state for rotation
  const [rotation, setRotation] = useState(0); // New state for rotation
  const [rotation2, setRotation2] = useState(0); // New state for rotation

  const [showSecondPlot, setShowSecondPlot] = useState(false); // show second plot for side by side comparison
  const [fromTimeString, setFromTimeString] = useState(false);

  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null); // second file for side by side comparison

  const [imageUrl, setImageUrl] = useState(''); // State to store the image SVG URL
  const [imageUrl2, setImageUrl2] = useState(''); // State to store the image SVG URL

  const [isInteractive, setIsInteractive] = useState(false);
  const [interactiveTooLarge, setInteractiveTooLarge] = useState(false);
  const interactiveRef = useRef(null);

  const [imagePngUrl, setImagePngUrl] = useState(''); // State to store the image SVG URL
  const [imagePngUrl2, setImagePngUrl2] = useState(''); // State to store the image SVG URL

  const transformWrapperRef = useRef(null); // Ref to access TransformWrapper
  const transformWrapperRef2 = useRef(null); // Ref to access TransformWrapper for side by side comparison

  const [wrapperStyle, setWrapperStyle] = useState({ // wrapper will always be identical for side-by-side comparison, so only need one
    height: '80vh',
    width: '80vw'
  });

  const [bounds, setBounds] = useState({ boundX: 0, boundY: 0 });
  const [basePairAnnotation, setBasePairAnnotation] = useState('dssrLw');
  const [uploadBasePairAnnotation, setUploadBasePairAnnotation] = useState(''); // used to set the legend
  const [loopBulging, setLoopBulging] = useState('0');
  const [mergeHelices, setMergeHelices] = useState(true);
  const [additionalFile, setAdditionalFile] = useState(null);
  
  const [timeString, setTimeString] = useState(null);
  const [timeString2, setTimeString2] = useState(null);
  const [savedTimeString, setSavedTimeString] = useState("");

  

  const [isLoading, setIsLoading] = useState(false);
  const [showDocumentation, setShowDocumentation] = useState(true);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [showProcessingSettings, setShowProcessingSettings] = useState(false);
  const [pdbid, setPdbid] = useState("");
  const [pdbid2, setPdbid2] = useState("");



  const [showLogPopup, setShowLogPopup] = useState(false);
  const [showLogPopup2, setShowLogPopup2] = useState(false);

  const [logText, setLogText] = useState('');
  const [logText2, setLogText2] = useState('');


  // Number color
  const [numberColor, setNumberColor] = useState("#8B4513");

  // Nucleotide colors
  const [colorG, setColorG] = useState("#90CC84");
  const [colorA, setColorA] = useState("#FF9896");
  const [colorU, setColorU] = useState("#AEC7E8");
  const [colorC, setColorC] = useState("#DBDB8D");
  const [colorX, setColorX] = useState("#FFFFFF");

  // other colors
  const [colorMarker, setColorMarker] = useState("#4169E1");
  const [colorBackbone, setColorBackbone] = useState("#000000");
  const [colorPairing, setColorPairing] = useState("#4169E1");


  const [magnification, setMagnification] = useState(1);
  const [arrowSize, setArrowSize] = useState(1);
  const [circleSize, setCircleSize] = useState(1);
  const [circleLabelSize, setCircleLabelSize] = useState(1);
  const [markerSize, setMarkerSize] = useState(1);

  const [numberSeparation, setNumberSeparation] = useState(1);
  const [numberSize, setNumberSize] = useState(1);
  const [showNumberLabels, setShowNumberLabels] = useState(false);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [numK, setNumK] = useState(10); // show residue numbers every K nucleotides


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalLink, setModalLink] = useState('');

  //width and height for interactive ref
  function getDimensions() {
    if (interactiveRef.current) {
      console.log("Running this ya boi");
      const heightInVh = window.innerHeight * 0.6; // 60% of the viewport height
      return { width: interactiveRef.current.offsetWidth * .80,
        height: heightInVh
      };
    }
    return { width: 0, height:0};
  }

  function handleIntZoomIn() {
    window.zoomIn20();
  }
  function handleIntZoomOut() {
    window.zoomOut20();
  }
  function handleIntCenter() {
    window.zoomFit(0.1, 800);
  }

  // Function to open modal with a custom message and link
  const openModal = (message, link) => {
    setModalMessage(message);
    setModalLink(link);
    setIsModalOpen(true);
  };

  let location = useLocation();
  // Function to handle the path
  const handlePath = (path) => {
    console.log('Current Path:', path);
    let splitPath = path.split('/');
    console.log(splitPath);
    
    if (splitPath.length > 2) {
      setCounter(0);
      setSavedTimeString(splitPath[2]);
      setTriggerSubmit(true);
    }

    else if (splitPath[1] !== ""){
      setPdbid(splitPath[1]);
      setTriggerSubmit(true);
    }
  };

  // submit the saved time string if path changes
  useEffect(() => {
    if (savedTimeString !== "" && triggerSubmit) {
      setShowSplash(false);
      getPlotFromTimestring(savedTimeString);
      setTriggerSubmit(false); // Reset back to false after handling submit
      setSavedTimeString("");
    }
  }, [savedTimeString, triggerSubmit]); // Listen to changes in both pdbid and triggerSubmit

  // submit the PDB ID if path changes
  useEffect(() => {
    if (pdbid !== "" && triggerSubmit) {
      setShowSplash(false);
      handleSubmit();
      setTriggerSubmit(false); // Reset back to false after handling submit
    }
  }, [pdbid, triggerSubmit]); // Listen to changes in both pdbid and triggerSubmit

  // monitor when the path changes. if it does, call handle path
  useEffect(() => {
    // Extract the path from the location
    const path = location.pathname;
    handlePath(path);
  }, [location]); // Re-run this effect if the location changes


  // Update main plot with a timestring
  function getPlotFromTimestring(newTimeString) {
    setIsLoading(true); // Start loading
    const url = baseUrl + `/rnaview/rnaview/get-from-timestring`;

    const params = {
      timeString: newTimeString,  // Assuming timeString is stored in state
    }

      axios.get(url, { params })
      .then(response => {
        // Handle the response
        // console.log('Labels regenerated:', response.data);
        setRotation(0)
        setImageUrl(response.data.image_url)
        setImagePngUrl(response.data.image_png_url);
        setSumRotation(0);
        setUploadBasePairAnnotation(response.data.bp_type);
        setTimeString(response.data.time_string);
        setFromTimeString(true);
        setInteractiveTooLarge(true);
        if(showDocumentation){
          toggleDocumentation(); // hide documentation if it is being shown!
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          if(error.response.data.error === "Structure is still processing, please try again later."){
            openModal("Structure is still processing, please try again later.", "");
          }
          else{
            alert(`Error: ${error.response.data.error}`);
          }
        } else {
            alert('Error retrieving your structure!');
        }
        throw error;
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
        setCounter(0);
      });
  }

  function updateWrapperStyle(newHeight, newWidth) {
    setWrapperStyle({ height: newHeight, width: newWidth });
  }

  function handleExitSecondPlot(){
    setShowSecondPlot(false);
    updateWrapperStyle('80vh', '80vw');
  }

  const handleCheckboxChange = (event) => {
    setShowNumberLabels(event.target.checked);
  };

  const handleMergeCheckboxChange = (event) => {
    setMergeHelices(event.target.checked);
  };

  const handleMarkerSizeChange = (event) => {
    let newMarkerSize = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newMarkerSize)) {
      newMarkerSize = 1;
    }

    // Clamp the newRotation
    newMarkerSize = Math.max((0.1), Math.min(newMarkerSize, 5));
  
    setMarkerSize(newMarkerSize);
  };

  const handleNumberSizeChange = (event) => {
    let newNumberSize = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newNumberSize)) {
      newNumberSize = 1;
    }

    // Clamp the newRotation
    newNumberSize = Math.max((0.1), Math.min(newNumberSize, 5));
  
    setNumberSize(newNumberSize);
  };


  const handleNumkChange = (event) => {
    let newNumberSize = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newNumberSize)) {
      newNumberSize = 1;
    }

    // Clamp the newRotation
    newNumberSize = Math.max((1), Math.min(newNumberSize, 100));
  
    setNumK(newNumberSize);
  };

  const handleNumberSeparationChange = (event) => {
    let newNumberSeparation = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newNumberSeparation)) {
      newNumberSeparation = 1;
    }

    // Clamp the newRotation
    newNumberSeparation = Math.max((0.1), Math.min(newNumberSeparation, 5));
  
    setNumberSeparation(newNumberSeparation);
  };


  const handleCircleLabelSizeChange = (event) => {
    let newCircleLabelSize = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newCircleLabelSize)) {
      newCircleLabelSize = 1;
    }

    // Clamp the newRotation
    newCircleLabelSize = Math.max((0), Math.min(newCircleLabelSize, 5));
  
    setCircleLabelSize(newCircleLabelSize);
  };


  const handleCircleSizeChange = (event) => {
    let newCircleSize = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newCircleSize)) {
      newCircleSize = 1;
    }

    // Clamp the newRotation
    newCircleSize = Math.max((0), Math.min(newCircleSize, 5));
  
    setCircleSize(newCircleSize);
  };

  const handleArrowSizeChange = (event) => {
    let newArrowSize = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newArrowSize)) {
      newArrowSize = 1;
    }

    // Clamp the newRotation
    newArrowSize = Math.max((0), Math.min(newArrowSize, 5));
  
    setArrowSize(newArrowSize);
  };

  const handleMagnificationChange = (event) => {
    let newMagnification = parseFloat(event.target.value);

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newMagnification)) {
      newMagnification = 1;
    }

    // Clamp the newRotation
    newMagnification = Math.max((0.1), Math.min(newMagnification, 5));
  
    setMagnification(newMagnification);
  };

  const handleAColorChange = (event) => {
    setColorA(event.target.value);
  };
  const handleUColorChange = (event) => {
    setColorU(event.target.value);
  };
  const handleGColorChange = (event) => {
    setColorG(event.target.value);
  };
  const handleCColorChange = (event) => {
    setColorC(event.target.value);
  };
  const handleXColorChange = (event) => {
    setColorX(event.target.value);
  };
  const handleMarkerColorChange = (event) => {
    setColorMarker(event.target.value);
  };
  const handleBackboneColorChange = (event) => {
    setColorBackbone(event.target.value);
  };
  const handlePairingColorChange = (event) => {
    setColorPairing(event.target.value);
  };


  const handleNumberColorChange = (event) => {
    setNumberColor(event.target.value);
  };

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  const toggleProcessingSettings = () => {
    setShowProcessingSettings(!showProcessingSettings);
  };


  const toggleDocumentation = () => {
    setShowDocumentation(!showDocumentation);
  };

  const calculateBounds = () => {
    const footerHeight = document.querySelector('.app-footer').clientHeight;
    const windowHeight = window.innerHeight;
    const boundY = windowHeight - footerHeight;
  
    setBounds({ boundX: 0, boundY });
  };
  
  useEffect(() => {
    window.addEventListener('resize', calculateBounds);
    return () => {
      window.removeEventListener('resize', calculateBounds);
    };
  }, []);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleChange2(event) {
    setFile2(event.target.files[0]);
  }
  
  function handleAdditionalFileChange(event) {
    setAdditionalFile(event.target.files[0]);
  }  

  async function handleDownloadLog() {
    try {
        const logText = await getLog(); // Wait for the log text to be retrieved

        // Create a new Blob object from the log text
        const file = new Blob([logText], { type: 'text/plain' });

        // Download the log file
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'structure_log.txt');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        URL.revokeObjectURL(fileURL);
    } catch (error) {
        console.error('Error handling log download:', error);
        alert('Error downloading log!');
    }
}


async function handleDownloadLog2() {
  try {
      const logText2 = await getLog2(); // Wait for the log text to be retrieved

      // Create a new Blob object from the log text
      const file = new Blob([logText2], { type: 'text/plain' });

      // Download the log file
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'structure_log.txt');
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
      URL.revokeObjectURL(fileURL);
  } catch (error) {
      console.error('Error handling log download:', error);
      alert('Error downloading log!');
  }
}

  // Handle downloading a log file. Call the endpoint and return the file
  // based on user time string
  function getLog() {
    const url = `${baseUrl}/rnaview/rnaview/get-log-file/`;

    // Return a Promise that resolves when the log text is set
    return new Promise((resolve, reject) => {
        console.log("Get log being called!");
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // Important
            params: { timeString: timeString },
        })
        .then((response) => {
            const file = new Blob([response.data], { type: 'text/plain' });
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                console.log("Text was: " + text);
                setLogText(text);
                resolve(text); // Resolve the Promise with the log text
            };
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        })
        .catch((error) => {
            console.error('Error downloading log!', error);
            reject(error);
        });
    });
}


function getLog2() {
  const url = `${baseUrl}/rnaview/rnaview/get-log-file/`;

  // Return a Promise that resolves when the log text is set
  return new Promise((resolve, reject) => {
      console.log("Get log being called!");
      axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // Important
          params: { timeString: timeString2 },
      })
      .then((response) => {
          const file = new Blob([response.data], { type: 'text/plain' });
          const reader = new FileReader();
          reader.onload = (e) => {
              const text = e.target.result;
              console.log("Text was: " + text);
              setLogText2(text);
              resolve(text); // Resolve the Promise with the log text
          };
          reader.onerror = (error) => reject(error);
          reader.readAsText(file);
      })
      .catch((error) => {
          console.error('Error downloading log!', error);
          reject(error);
      });
  });
}


  const handleShowLog = (event) => {
    event.preventDefault();
    // Set the log text and show the popup
    getLog();
    setShowLogPopup(true);
  };

  const handleShowLog2 = (event) => {
    event.preventDefault();
    // Set the log text and show the popup
    getLog2();
    setShowLogPopup2(true);
  };



  // Handle downloading an NPZ file. Call the endpoint and return the file
  // based on user time string
  function handleDownloadNpz() {
    const url = baseUrl + '/rnaview/rnaview/get-npz-file/';
    // setIsLoading(true); // Start loading
  
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // Important
      params: {
        timeString: timeString, // Assuming timeString is stored in state
      }
    })
    .then((response) => {
      // Create a new Blob object using the response data of the file
      const file = new Blob([response.data], { type: 'application/npz' });
  
      // Create a link element, set the href to the blob URL, and trigger the download
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `processed_structure.npz`); // Set the download attribute (will be saved as this name)
      document.body.appendChild(fileLink);
  
      fileLink.click();
  
      // Clean up and revoke the Object URL after download
      document.body.removeChild(fileLink);
      URL.revokeObjectURL(fileURL);
    })
    .catch((error) => {
      alert('Error downloading NPZ!');
      console.error('Error downloading NPZ!', error);
    })
    .finally(() => {
      // setIsLoading(false); // Stop loading
    });
  }
  

  // Main function to handle uploading a file!
  // Also handles case when a structure has too many nucleotides, and a link is provided!
  function handleSubmit(event) {
    setCounter(0);
    const url = baseUrl + '/rnaview/rnaview/run-rnascape/';
    if(event){
      event.preventDefault();
    }
    setIsLoading(true); // Start loading
    if (!file && pdbid === "") {
      alert('Please select a file or enter a PDB ID!');
      setIsLoading(false);
      return;
    }

    if (file && pdbid !== "") {
      alert('A PDB ID and file are both selected as input. Please either clear the PDB ID text or reload the page to clear the file.');
      setIsLoading(false);
      return;
    }

    // Check if additional file is required and selected
    if (basePairAnnotation === 'rnaview' && !additionalFile) {
      alert('Please select an output file from RNAView!');
      setIsLoading(false);
      return;
  }

    const formData = new FormData();
    formData.append('file', file);
    let filenameToPass = null;
    if(file !== null){
      filenameToPass = file.name;
    }
    formData.append('fileName', filenameToPass);
    formData.append('basePairAnnotation', basePairAnnotation);
    formData.append('loopBulging', loopBulging);
    formData.append('counter', 0);

    if(pdbid === ""){
      formData.append('pdbid', '0');
    }else{
      formData.append('pdbid', pdbid);
    }

    if(mergeHelices){
      formData.append('mergeHelices', '1');
    }else{
      formData.append('mergeHelices', '0');
    }

    // advanced options
    formData.append('arrowSize', arrowSize);
    formData.append('circleSize', circleSize);
    formData.append('circleLabelSize', circleLabelSize);
    formData.append('markerSize', markerSize);


    // colors
    formData.append('colorA', colorA);
    formData.append('colorC', colorC);
    formData.append('colorG', colorG);
    formData.append('colorU', colorU);
    formData.append('colorX', colorX);
    formData.append('colorBackbone', colorBackbone);
    formData.append('colorPairing', colorPairing);
    formData.append('colorMarker', colorMarker);

    // number labels
    if(showNumberLabels){
      formData.append('showNumberLabels', "1");
    }else{
      formData.append('showNumberLabels', "0");
    }

    formData.append('numberSeparation', numberSeparation);
    formData.append('numberSize', numberSize);
    formData.append('numberColor', numberColor);
    formData.append('numK', numK);

    axios.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }).then(response => {
      let modal_msg = "Because this structure is larger than 7 MB, it is being processed and will be available shortly. Please note that output for extremely large structures may not be well suited for presentation; however, it may still prove informative. Please save or bookmark the below link. Access will expire after 24 hours."
      // Check if structure is too big, and a link is returned instead
      if (response.data.image_url === 'tooBig'){
        setImageUrl("");
        setShowDocumentation(true);
        openModal(modal_msg, baseUrl + '/link/' + response.data.time_string);
      }else{
        // Otherwise Set the image URL in the state
        setSumRotation(0);
        setImageUrl(response.data.image_url);
        setImagePngUrl(response.data.image_png_url);

        setUploadBasePairAnnotation(basePairAnnotation);
        setTimeString(response.data.time_string);
        setCounter(1);
        setFromTimeString(false);
        setInteractiveTooLarge(false);
        if(showDocumentation){
          toggleDocumentation(); // hide documentation if it is being shown!
        }
      }
     
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
          alert('Error loading file! This is most commonly caused by a structure file that is too large, formatted improperly, or by an incorrect PDB ID.');
      }

    }).finally(() => {
      setIsLoading(false); // Stop loading
      setShowSplash(false);
      setIsInteractive(false);
    });
  }


  // Main function to handle uploading a file!
  function handleSecondSubmit(event) {
    const url = baseUrl + '/rnaview/rnaview/run-rnascape/';
    if(event){
      event.preventDefault();
    }
    setIsLoading(true); // Start loading
    if (!file2 && pdbid2 === "") {
      alert('Please select a second file or enter another PDB ID!');
      setIsLoading(false);
      return;
    }

    if (file2 && pdbid2 !== "") {
      alert('A second PDB ID and second file are both selected as input. Please either clear the PDB ID text or reload the page to clear the file.');
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', file2);
    let filenameToPass = null;
    if(file2 !== null){
      filenameToPass = file2.name;
    }
    formData.append('fileName', filenameToPass);
    formData.append('basePairAnnotation', basePairAnnotation);
    formData.append('loopBulging', loopBulging);
    formData.append('counter', counter);
    if(pdbid2 === ""){
      formData.append('pdbid', '0');
    }else{
      formData.append('pdbid', pdbid2);
    }
    if(mergeHelices){
      formData.append('mergeHelices', '1');
    }else{
      formData.append('mergeHelices', '0');
    }

    // advanced options
    formData.append('arrowSize', arrowSize);
    formData.append('circleSize', circleSize);
    formData.append('circleLabelSize', circleLabelSize);
    formData.append('markerSize', markerSize);


    // colors
    formData.append('colorA', colorA);
    formData.append('colorC', colorC);
    formData.append('colorG', colorG);
    formData.append('colorU', colorU);
    formData.append('colorX', colorX);
    formData.append('colorBackbone', colorBackbone);
    formData.append('colorPairing', colorPairing);
    formData.append('colorMarker', colorMarker);

    // number labels
    if(showNumberLabels){
      formData.append('showNumberLabels', "1");
    }else{
      formData.append('showNumberLabels', "0");
    }
    formData.append('numberSeparation', numberSeparation);
    formData.append('numberSize', numberSize);
    formData.append('numberColor', numberColor);
    formData.append('numK', numK);

    axios.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }).then(response => {
      // Set the image URL in the state
      // setSumRotation(0);
      setImageUrl2(response.data.image_url);
      setImagePngUrl2(response.data.image_png_url);
      setTimeString2(response.data.time_string);
      console.log(response.data.image_url);

      if(showDocumentation){
        toggleDocumentation(); // hide documentation if it is being shown!
      }
      updateWrapperStyle('70vh', '42vw');
      setShowSecondPlot(true);
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
          alert('Error loading file! This is most commonly caused by a structure file that is too large, formatted improperly, or by an incorrect PDB ID.');
      }
    }).finally(() => {
      setIsLoading(false); // Stop loading
      // setCounter(counter+1);
      setShowSplash(false);
    });
  }

  // Automatically load example structure
  const loadExampleData = () => {
    setCounter(0);
    const url = baseUrl + '/rnaview/rnaview/run-rnascape/';
    setIsLoading(true); // Start loading
    fetch('/3zp8-assembly1.cif')
      .then(response => response.blob())
      .then(blob => {
        // Create a File object from the blob
        const file = new File([blob], "3zp8-assembly1.cif"); // Adjust filename as needed
        
        // Programmatically set the file to your state and initiate upload
        setFile(file);
          // Check if additional file is required and selected
        if (basePairAnnotation === 'rnaview') {
          alert('Unable to use RNAView output for the example!');
          setIsLoading(false);
          return;
        }
        

        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        formData.append('basePairAnnotation', basePairAnnotation);
        formData.append('loopBulging', loopBulging);
        formData.append('counter', 0);
        formData.append('pdbid', '0'); // guarantee empty pdb id
        console.log(mergeHelices);
        if(mergeHelices){
          formData.append('mergeHelices', "1");
        }else{
          formData.append('mergeHelices', "0");
        }

        // advanced options
        formData.append('arrowSize', arrowSize);
        formData.append('circleSize', circleSize);
        formData.append('circleLabelSize', circleLabelSize);
        formData.append('markerSize', markerSize);

        // colors
        formData.append('colorA', colorA);
        formData.append('colorC', colorC);
        formData.append('colorG', colorG);
        formData.append('colorU', colorU);
        formData.append('colorX', colorX);
        formData.append('colorBackbone', colorBackbone);
        formData.append('colorPairing', colorPairing);
        formData.append('colorMarker', colorMarker);
        // number labels
        if(showNumberLabels){
          formData.append('showNumberLabels', "1");
        }else{
          formData.append('showNumberLabels', "0");
        }
        formData.append('numberSeparation', numberSeparation);
        formData.append('numberSize', numberSize);
        formData.append('numberColor', numberColor);
        formData.append('numK', numK);


        axios.post(url, formData, {
          headers: {
            'content-type': 'multipart/form-data',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          withCredentials: true,
        }).then(response => {
          // Set the image URL in the state
          if (response.data.image_url === 'tooBig'){
            let modal_msg = "Because this structure is larger than 7 MB, it is being processed and will be available shortly. Please note that output for extremely large structures may not be well suited for presentation; however, it may still prove informative. Please save or bookmark the below link. Access will expire after 24 hours."
            openModal(modal_msg, baseUrl + '/link/' + response.data.time_string);            
          }else{
            setSumRotation(0);
            setUploadBasePairAnnotation(basePairAnnotation);
            setImageUrl(response.data.image_url);
            setImagePngUrl(response.data.image_png_url);
            console.log("Load example counter");
            console.log(counter);
            setCounter(1);
            console.log("After load example counter");
            console.log(counter);
            setTimeString(response.data.time_string);
            setFromTimeString(false);
            setInteractiveTooLarge(false);
            if(showDocumentation){
              toggleDocumentation(); // hide documentation if it is being shown!
            }            
          }
          
        }).catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            alert(`Error: ${error.response.data.error}`);
          } else {
              alert('Error loading example file!');
          }
          console.error('Error uploading file:', error);
        }).finally(() => {
          setIsLoading(false); // Stop loading
          setFile(null);
          setIsInteractive(false);
        });
      })
      .catch(error => console.error('Error loading example data:', error))
  };


 // Send timeString and rotation via axios get request to run_regen_labels
 function testDjango(event) {
  setIsLoading(true); // Start loading
  // Define the URL for the GET request
  const url = baseUrl+`/rnaview/rnaview/test-get/`;

  // Set up the query parameters
  const params = {
    timeString: timeString,  // Assuming timeString is stored in state
    rotation: parseInt(rotation) + parseInt(sumRotation)       // Assuming rotation is stored in state
  };

  // Send the GET request with the query parameters
  return axios.get(url, { params })
    .then(response => {
      // Handle the response
      // console.log('Labels regenerated:', response.data);
      alert("SUCCESS!")
      return true;
      // You might want to update some state here based on the response
    })
    .catch(error => {
      alert('Error regenerating labels!');
      console.error('Error regenerating labels:', error);
      throw error;
    })
    .finally(() => {
      setIsLoading(false); // Stop loading
    });
}




  // Send timeString and rotation via axios get request to run_regen_labels
  function handleRegenPlot(event) {
    setIsLoading(true); // Start loading

    // Define the URL for the POST request
    const url = baseUrl + `/rnaview/rnaview/run-regen_plot/`;
    var toAddShowNumberLabels;
    if(showNumberLabels){
      toAddShowNumberLabels = "1";
    } else{
      toAddShowNumberLabels = "0";
    }
    var positions = "false";
    if(isInteractive){
        if (window.getNodePositions) {
            positions = window.getNodePositions();
            // Now nodePositions state contains the coordinates
            // You can use them here or pass to other components/functions
            console.log("Node Positions:", positions);
    }}
    // Set up the query parameters
    const formData = new FormData();
    formData.append('timeString', timeString);
    formData.append('rotation', parseInt(rotation) + parseInt(sumRotation));
    formData.append('basePairAnnotation', basePairAnnotation);

    formData.append('colorA', colorA);
    formData.append('colorC', colorC);
    formData.append('colorG', colorG);
    formData.append('colorU', colorU);
    formData.append('colorX', colorX);
    formData.append('circleSize', circleSize);
    formData.append('circleLabelSize', circleLabelSize);
    formData.append('arrowSize', arrowSize);
    formData.append('showNumberLabels', toAddShowNumberLabels);
    formData.append('numberSeparation', numberSeparation);
    formData.append('numberSize', numberSize);
    formData.append('numberSize', numberSize);
    formData.append('counter', counter);
    formData.append('markerSize', markerSize);
    formData.append('numberColor', numberColor);
    formData.append('numK', numK);
    formData.append('colorBackbone', colorBackbone);
    formData.append('colorPairing', colorPairing);
    formData.append('colorMarker', colorMarker);
    formData.append('nodePositions', positions);


    // const params = {
    //   timeString: timeString,  // Assuming timeString is stored in state
    //   rotation: parseInt(rotation) + parseInt(sumRotation),       // Assuming rotation is stored in state
    //   basePairAnnotation: basePairAnnotation,
    //   colorA: colorA,
    //   colorC: colorC,
    //   colorG: colorG,
    //   colorU: colorU,
    //   colorX: colorX,
    //   circleSize: circleSize,
    //   circleLabelSize: circleLabelSize,
    //   arrowSize: arrowSize,
    //   showNumberLabels: toAddShowNumberLabels,
    //   numberSeparation: numberSeparation,
    //   numberSize: numberSize,
    //   counter:counter,
    //   markerSize: markerSize,
    //   numberColor: numberColor,
    //   numK: numK,
    //   colorBackbone: colorBackbone,
    //   colorPairing: colorPairing,
    //   colorMarker: colorMarker,
    //   nodePositions: positions,
    // };

    // Send the GET request with the query parameters
    return axios.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }).then(response => {
        // Handle the response
        // console.log('Labels regenerated:', response.data);
        setSumRotation(parseInt(rotation)+parseInt(sumRotation))
        setRotation(0)
        setImageUrl(response.data.image_url)
        setImagePngUrl(response.data.image_png_url);
        setIsInteractive(false);
        return response.data.image_url;
        // You might want to update some state here based on the response
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          alert(`Error: ${error.response.data.error}`);
        } else {
            alert('Error regenerating plot!');
        }
        throw error;
      })
      .finally(() => {
        setCounter(counter + 1);
        setIsInteractive(false);
        setIsLoading(false); // Stop loading
        setUploadBasePairAnnotation(basePairAnnotation);
      });
  }
  const downloadImage = (url, isPng) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const localUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = localUrl;
        if(isPng){
          link.download = 'mapped-image.png'
        } else{
          link.download = 'mapped-image.svg';
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(localUrl); // Clean up the URL
      })
      .catch(error => {
        alert('Error downloading the image!');
      });
  };
  
  const handleDownloadAndRegenerate = (type) => {
    if (rotation !== 0 && rotation !== 360) {
      // If rotation is not 0/360, regenerate labels and then download
      // handleRegenLabels().then(newImageUrl => {
      //   downloadImage(newImageUrl);
      // });
      if(type === "PNG"){
        rotateAndDownloadPNG(imagePngUrl, rotation);
      }
      else{
        downloadRotatedSVG(imageUrl, rotation);
      }
    } else {
      // If rotation is 0/360, directly download the current image
      if(type === "PNG"){
        downloadImage(imagePngUrl, true);
      }
      else{
        downloadImage(imageUrl, false);
      }
    }
  };

  const handleDownloadAndRegenerate2 = (type) => {
    if (rotation2 !== 0 && rotation2 !== 360) {
      // If rotation is not 0/360, regenerate labels and then download
      // handleRegenLabels().then(newImageUrl => {
      //   downloadImage(newImageUrl);
      // });
      if(type === "PNG"){
        rotateAndDownloadPNG(imagePngUrl2, rotation2);
      }
      else{
        downloadRotatedSVG(imageUrl2, rotation2);
      }
    } else {
      // If rotation is 0/360, directly download the current image
      if(type === "PNG"){
        downloadImage(imagePngUrl2, true);
      }
      else{
        downloadImage(imageUrl2, false);
      }
    }
  };
  

  const rotateAndDownloadSVG = (svgText, rotationDegrees) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgText, "image/svg+xml");

    const svgElement = xmlDoc.documentElement;
    const firstGElement = xmlDoc.querySelector('g');
    
    if (firstGElement) {
        // Original dimensions
        const originalWidth = parseFloat(svgElement.getAttribute("width"));
        const originalHeight = parseFloat(svgElement.getAttribute("height"));

        // Calculate center
        const centerX = originalWidth / 2;
        const centerY = originalHeight / 2;

        // Apply rotation around the center
        const rotationTransform = `rotate(${rotationDegrees}, ${centerX}, ${centerY})`;
        firstGElement.setAttribute('transform', rotationTransform);

        // Adjust viewBox to fit the rotated image
        // The new viewBox dimensions might need to be adjusted based on the rotation
        const maxDim = Math.max(originalWidth, originalHeight) * Math.sqrt(2); // sqrt(2) accounts for rotation
        const newViewBoxX = centerX - maxDim / 2;
        const newViewBoxY = centerY - maxDim / 2;
        const newViewBox = `${newViewBoxX} ${newViewBoxY} ${maxDim} ${maxDim}`;
        svgElement.setAttribute('viewBox', newViewBox);
    }

    // Serialize the modified SVG back to a string
    const serializer = new XMLSerializer();
    const rotatedSVGText = serializer.serializeToString(xmlDoc);

    // Convert the SVG string to a blob
    const blob = new Blob([rotatedSVGText], {type: "image/svg+xml"});

    // Create a download link for the blob
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'rotated-image.svg';  // Setting the download filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
};

const rotateAndDownloadPNG = (imagePngUrl, rotationDegrees) => {
  const image = new Image();
  image.crossOrigin = "Anonymous"; // Handle CORS if needed
  image.src = imagePngUrl;

  image.onload = () => {
    // Calculate the dimensions of the rotated image
    const radians = rotationDegrees * Math.PI / 180;
    const sin = Math.abs(Math.sin(radians));
    const cos = Math.abs(Math.cos(radians));
    const newWidth = image.width * cos + image.height * sin;
    const newHeight = image.width * sin + image.height * cos;

    // Create a canvas with dimensions to fit the rotated image
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext('2d');

    // Fill the canvas with a white rectangle
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Move the origin to the center of the new canvas
    ctx.translate(newWidth / 2, newHeight / 2);

    // Rotate the canvas
    ctx.rotate(radians);

    // Draw the image so that its center aligns with the canvas origin
    ctx.drawImage(image, -image.width / 2, -image.height / 2);

    // Convert the canvas to a data URL in PNG format
    const dataURL = canvas.toDataURL('image/png');

    // Download the rotated image
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'rotated-image.png'; // Set the download file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  image.onerror = () => {
    console.error('Error loading the image:', imagePngUrl);
  };
};



  const downloadRotatedSVG = (url, curRotation) => {
    fetch(url)
      .then(response => response.text())
      .then(svgText => {
        rotateAndDownloadSVG(svgText, curRotation);
      })
      .catch(error => {
        alert('Error fetching the SVG!');
        console.error('Error fetching the SVG:', error);
      });
  };


  // const downloadImage = () => {
  //   fetch(imageUrl)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const rotationDegrees = rotation;
  //       const localUrl = window.URL.createObjectURL(blob);
  //       const image = new Image();
  //       image.src = localUrl;
  //       console.log(rotationDegrees);
  //       image.onload = () => {
  //         console.log("Hi!");
  //         const canvas = document.createElement('canvas');
  //         const ctx = canvas.getContext('2d');
  
  //         // Set canvas size to image size
  //         canvas.width = image.width;
  //         canvas.height = image.height;
  //         console.log(image.width);
  //         console.log(image.height);

  //         // Apply rotation
  //         ctx.translate(canvas.width / 2, canvas.height / 2);
  //         ctx.rotate(rotationDegrees * Math.PI / 180);  // Convert degrees to radians
  //         ctx.drawImage(image, -image.width / 2, -image.height / 2);
  
  //         // Convert canvas to image for download
  //         canvas.toBlob(blob => {
  //           const rotatedUrl = window.URL.createObjectURL(blob);
  //           const link = document.createElement('a');
  //           link.href = rotatedUrl;
  //           link.download = 'rotated-image.png'; // Or dynamically set filename
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //           window.URL.revokeObjectURL(localUrl);
  //           window.URL.revokeObjectURL(rotatedUrl);
  //         }, 'image/png', 1); // '1' for maximum quality
  //       };
  //     })
  //     .catch(error => {
  //       console.error('Error rotating and downloading the image:', error);
  //     });
  // };
  


  const transformOptions = {
    initialScale: 1,
    minScale: 0.1,
    maxScale: 2,
    // centerOnInit: true
  }

  const onImageLoad = () => {
    centerImage();
    calculateBounds();
  };

  useEffect(() => {
    calculateBounds();
  }, [imageUrl]); // Recalculate bounds when the image URL changes
  

  const centerImage = () => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.centerView();
    }
  };

  const resetTransform = () => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform();
    }
  };

  const zoomIn = () => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.zoomIn();
    }
  };

  const zoomOut = () => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.zoomOut();
    }
  };

  const centerImage2 = () => {
    if (transformWrapperRef2.current) {
      transformWrapperRef2.current.centerView();
    }
  };

  const resetTransform2 = () => {
    if (transformWrapperRef2.current) {
      transformWrapperRef2.current.resetTransform();
    }
  };

  const zoomIn2 = () => {
    if (transformWrapperRef2.current) {
      transformWrapperRef2.current.zoomIn();
    }
  };

  const zoomOut2 = () => {
    if (transformWrapperRef2.current) {
      transformWrapperRef2.current.zoomOut();
    }
  };

  const handleRotationChange = (event) => {
    let newRotation = parseInt(event.target.value, 10) - sumRotation;

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newRotation)) {
      newRotation = 0;
    }

    // Clamp the newRotation
    newRotation = Math.max((-1*sumRotation), Math.min(newRotation, 360));
  
    setRotation(newRotation);
  };

  const handleRotationChange2 = (event) => {
    let newRotation = parseInt(event.target.value, 10) - sumRotation2;

    // Check if the number is NaN (not a number), if so, set it to the default (e.g., 0)
    if (isNaN(newRotation)) {
      newRotation = 0;
    }

    // Clamp the newRotation
    newRotation = Math.max((-1*sumRotation2), Math.min(newRotation, 360));
  
    setRotation2(newRotation);
  };

 // Function to determine the image source based on basePairAnnotation
  const getImageSrc = () => {
    switch (uploadBasePairAnnotation) {
      case 'dssrLw':
        return '/lw_legend.svg';
      case 'rnaview':
        return '/lw_legend.svg';
      case 'dssr':
        return '/dssr_legend.svg';
      // Add more cases as needed
      default:
        return '/lw_legend.svg'; // Default image
    }
  };
  
  const handlePdbChange = (event) => {
    setPdbid(event.target.value); // Update the pdbId state with the new value from the input field
  }

  const handlePdbChange2 = (event) => {
    setPdbid2(event.target.value); // Update the pdbId state with the new value from the input field
  }

  //<option value="rnaview">Leontis-Westhof (RNAView)</option>
  return (
    <div className="App">
      <div class="page-container">
      {showSplash && <SplashScreen />}
      <TopRow onToggleDocumentation={toggleDocumentation} showDocumentation={showDocumentation} onLoadExampleData={loadExampleData} />
      <Modal 
        isOpen={isModalOpen} 
        message={modalMessage} 
        link={modalLink} 
        onClose={() => setIsModalOpen(false)} 
      />
      <form onSubmit={handleSubmit} className="upload-form">
        <div className="same-line-div">
          <div className="input-group">
            <label>Upload mmCIF/PDB file: </label>
            <input type="file" onChange={handleChange} />
          </div>
          
          <div className="input-group">
            <label>Or enter PDB ID:</label>
            <input id="small-text" type="text" onChange={handlePdbChange} value={pdbid} placeholder="6R47, 6UFH, 4NOE" /> {/* Added value attribute for controlled input */}
          </div>
        </div>

        {/* {basePairAnnotation === 'rnaview' && (
          <>
            <label class="pad-label" htmlFor="additional-file">Additional File for RNAView:</label>
            <input 
              type="file" 
              onChange={handleAdditionalFileChange} 
              id="additional-file" 
              required 
            />
          </>
        )} */}
        <div id="row-2">
        <button class="beige-button" type="button" onClick={toggleProcessingSettings}>
          Processing Settings
        </button>
        <button class="beige-button" type="button" onClick={toggleAdvancedSettings}>
          {/* {showAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'} */}
          Visual Settings
        </button>
      <button type="submit">Run</button>
      </div>
      {showProcessingSettings && (
      <div id="processing-div">
        <label className="merge-check">
              Ladder Orientation Post-Processing:
              <input
                  id="merge-checkbox"
                  type="checkbox"
                  checked={mergeHelices}
                  onChange={handleMergeCheckboxChange}
              />
          </label>
          <label>Bulge Out Loops:</label>
          <select 
            className="options-dropdown"
            value={loopBulging}
            onChange={(e) => setLoopBulging(e.target.value)}>
            <option value="0">Always (Default)</option>
            <option value="1">Conditional</option>
          </select>
        </div>
        )}
      {showAdvancedSettings && (
           <div className="advanced-settings">
           <div className="nucleotide-settings-container">
             <div className="nucleotide-settings-header">
               <h3>Nucleotide Settings</h3>
             </div>
             <div className="nucleotide-settings-content">
               <div className="slider-container">
                <label for="basePairAnnotation">Base Pair Annotation:</label>
                <div className="inputs-container">
                  <select 
                    id="nuc-settings-bp-dropdown"
                    className="options-dropdown"
                    value={basePairAnnotation}
                    onChange={(e) => setBasePairAnnotation(e.target.value)}
                  >
                    <option value="dssrLw">Leontis-Westhof (Default)</option>
                    <option value="dssr">DSSR</option>
                    <option value="saenger">Saenger</option>
                    <option value="none">None</option>
                  </select>
               </div></div>
               <div className="slider-container">
                  <label for="arrowSize">Arrow Size:</label>
                  <div className="inputs-container">
                    <input
                      type="range"
                      min="0"
                      max="5"
                      step="0.01"
                      id="arrowSizeRange"
                      value={arrowSize}
                      onChange={handleArrowSizeChange}
                    />
                    <input
                      type="number"
                      min="0"
                      max="5"
                      step="0.01"
                      id="arrowSizeNumber"
                      value={arrowSize}
                      onChange={handleArrowSizeChange}
                      style={{ width: '50px' }}
                    />
                  </div>
                </div>
                <div className="slider-container">
                  <label for="circleSize">Circle Size:</label>
                  <div className="inputs-container">
                <input
                  type="range"
                  min="0"
                  max="5"
                  step="0.01" // Step value to allow decimals
                  value={circleSize}
                  onChange={handleCircleSizeChange}
                />
                <input
                  type="number"
                  min="0"
                  max="5"
                  step="0.01" // Step value to allow decimals
                  value={circleSize}
                  onChange={handleCircleSizeChange}
                  style={{ width: '50px' }}
                />
                </div>
                </div>

                <div className="slider-container">
                  <label for="circleLabelSize">Circle Label Size:</label>
                  <div className="inputs-container">
                <input
                  type="range"
                  min="0"
                  max="5"
                  step="0.01" // Step value to allow decimals
                  value={circleLabelSize}
                  onChange={handleCircleLabelSizeChange}
                />
                <input
                  type="number"
                  min="0"
                  max="5"
                  step="0.01" // Step value to allow decimals
                  value={circleLabelSize}
                  onChange={handleCircleLabelSizeChange}
                  style={{ width: '50px' }}
                />
                </div></div>
                <div className="slider-container">
                  <label for="markerSize">Marker Size:</label>
                  <div className="inputs-container">
                    <input
                        type="range"
                        min="0.1"
                        max="5"
                        step="0.01"
                        value={markerSize}
                        onChange={handleMarkerSizeChange}
                    />
                    <input
                        type="number"
                        min="0.1"
                        max="5"
                        step="0.01"
                        value={markerSize}
                        onChange={handleMarkerSizeChange}
                        style={{ width: '50px' }}
                    />
                </div></div>
            </div>
            </div>

    <div id="number-settings-div" className="number-settings-container">
    <div className="number-settings-header">
        <h3>Number Settings</h3>
    </div>
    <div className="number-settings-content">
        <label className="checkbox-label">
            <input
                type="checkbox"
                checked={showNumberLabels}
                onChange={handleCheckboxChange}
            />
            Show Residue Numbers
        </label>
        {showNumberLabels && (
             <div className="slider-container">
             <label for="circleSize">Frequency:</label>
                <div className="inputs-container">
                    <input
                        type="range"
                        min="1"
                        max="100"
                        step="1"
                        value={numK}
                        onChange={handleNumkChange}
                    />
                    <input
                        type="number"
                        min="1"
                        max="100"
                        step="1"
                        value={numK}
                        onChange={handleNumkChange}
                        style={{ width: '50px' }}
                    />
                </div>
                <div className="slider-container">
                  <label id="num-label-size-label" for="numberLabelSize">Number Label Size:</label>
                  <div className="inputs-container">
                    <input
                        type="range"
                        min="0.1"
                        max="5"
                        step="0.01"
                        value={numberSize}
                        onChange={handleNumberSizeChange}
                    />
                    <input
                        type="number"
                        min="0.1"
                        max="5"
                        step="0.01"
                        value={numberSize}
                        onChange={handleNumberSizeChange}
                        style={{ width: '50px' }}
                    />
                </div></div>
                <div className="slider-container">
                  <label for="numberDistance">Number Distance:</label>
                  <div className="inputs-container">
                    <input
                        type="range"
                        min="0.1"
                        max="5"
                        step="0.01"
                        value={numberSeparation}
                        onChange={handleNumberSeparationChange}
                    />
                    <input
                        type="number"
                        min="0.1"
                        max="5"
                        step="0.01"
                        value={numberSeparation}
                        onChange={handleNumberSeparationChange}
                        style={{ width: '50px' }}
                    />
                </div></div>
                <div className="color-container">
                  <label htmlFor="color-picker">Color: </label>
                  <input
                    type="color"
                    id="color-picker-number"
                    value={numberColor}
                    onChange={handleNumberColorChange}
                  />
                  <input
                    type="text"
                    value={numberColor}
                    onChange={handleNumberColorChange}
                    pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                    title="Enter a valid hex color code"
                  />
                </div>
            </div>
        )}
    </div>
</div>

<div id="second-settings-div" className="nucleotide-settings-container">
    <div className="nucleotide-settings-header">
        <h3>Add Second Plot</h3>
    </div>
    <div className="nucleotide-settings-content">
    <div className="slider-container">
                  <label for="markerSize">Enter PDB ID:</label>
                  <div className="inputs-container">
              <input id="small-text" type="text" onChange={handlePdbChange2} value={pdbid2} placeholder="6R47, 6UFH, 4NOE" /> {/* Added value attribute for controlled input */}
      </div></div>
      <div className="slider-container">
                  <label for="markerSize">Or upload structure:</label>
                  <div className="inputs-container">
            <input type="file" onChange={handleChange2} />
      </div></div>
      <div className="button-container">
      <button onClick={handleSecondSubmit}>Add Second Plot</button>
      </div>
    </div>
</div>

  
        <div id="number-picker-div">
        <div className="color-settings-container">
          <div className="color-settings-header">
            <h3>Color Settings</h3>
          </div>
        <div className="color-settings-content">
        <div className="color-container">
          <label htmlFor="color-picker">A: </label>
          <input
            type="color"
            id="color-picker-a"
            value={colorA}
            onChange={handleAColorChange}
          />
          <input
            type="text"
            value={colorA}
            onChange={handleAColorChange}
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            title="Enter a valid hex color code"
          />
          </div>
        <div className="color-container">
          <label htmlFor="color-picker">U: </label>
          <input
            type="color"
            id="color-picker-u"
            value={colorU}
            onChange={handleUColorChange}
          />
          <input
            type="text"
            value={colorU}
            onChange={handleUColorChange}
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            title="Enter a valid hex color code"
          />
        </div>
          <div className="color-container">

            <label htmlFor="color-picker">G: </label>

                    <input
            type="color"
            id="color-picker-g"
            value={colorG}
            onChange={handleGColorChange}
          />
          <input
            type="text"
            value={colorG}
            onChange={handleGColorChange}
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            title="Enter a valid hex color code"
          />
        </div>
        <div className="color-container">
          <label htmlFor="color-picker">C: </label>

          <input
            type="color"
            id="color-picker-c"
            value={colorC}
            onChange={handleCColorChange}
          />
          <input
          type="text"rnascape
          value={colorC}
          onChange={handleCColorChange}
          pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
          title="Enter a valid hex color code"
          />
        </div>
        <div className="color-container">
          <label htmlFor="color-picker">X: </label>

          <input
            type="color"
            id="color-picker-x"
            value={colorX}
            onChange={handleXColorChange}
          />
          <input
          type="text"
          value={colorX}
          onChange={handleXColorChange}
          pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
          title="Enter a valid hex color code"
          />
        </div>
        <div className="color-container">
          <label htmlFor="color-picker">Chain:</label>
          <input
            type="color"
            id="color-picker-a"
            value={colorBackbone}
            onChange={handleBackboneColorChange}
          />
          <input
            type="text"
            value={colorBackbone}
            onChange={handleBackboneColorChange}
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            title="Enter a valid hex color code"
          />
          </div><div className="color-container">
          <label htmlFor="color-picker">Pairing: </label>
          <input
            type="color"
            id="color-picker-a"
            value={colorPairing}
            onChange={handlePairingColorChange}
          />
          <input
            type="text"
            value={colorPairing}
            onChange={handlePairingColorChange}
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            title="Enter a valid hex color code"
          />
          </div><div className="color-container">
          <label htmlFor="color-picker">Marker: </label>
          <input
            type="color"
            id="color-picker-a"
            value={colorMarker}
            onChange={handleMarkerColorChange}
          />
          <input
            type="text"
            value={colorMarker}
            onChange={handleMarkerColorChange}
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            title="Enter a valid hex color code"
          />
          </div>
        </div>
        </div>
        </div>
          </div>   
      )}
      </form>
        {isLoading && (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      )}
      {!showDocumentation && !isLoading && imageUrl && (
      <div className="controls">
      {/* Always display the Modify Coordinates button */}
      {isInteractive && !showSecondPlot && !interactiveTooLarge && (
        <>
        <button onClick={handleIntZoomIn}>Zoom In</button>
        <button onClick={handleIntZoomOut}>Zoom Out</button>
        <button onClick={handleIntCenter}>Center & Reset Zoom</button>

        {/* Add Center if desired */}
        </>
      )}
      {/* Conditional rendering for other controls */}
      {!isInteractive && (
        <>
          {showSecondPlot && (
            <p><b>Left Plot:</b></p>
          )}
          <button onClick={zoomIn}>Zoom In</button>
          <button onClick={zoomOut}>Zoom Out</button>
          <button onClick={centerImage}>Center</button>
          <button onClick={resetTransform}>Reset</button>
          <label>Rotate Image:</label>
          <input 
            type="range" 
            min="0" 
            max="360" 
            value={rotation + sumRotation} 
            onChange={handleRotationChange}
          />
          <input
            type="number"
            min="0"
            max="360"
            value={rotation + sumRotation}
            onChange={handleRotationChange}
            style={{ marginLeft: '0px', width: '50px' }} // Adjust style as needed
          />
          <button type="button" onClick={(e) => handleShowLog(e)}>Log</button>
          <div className="dropdown">
            <button type="button" className="dropbtn">Download</button>
            <div className="dropdown-content">
              <a href="#" onClick={() => handleDownloadAndRegenerate('SVG')}>SVG</a>
              <a href="#" onClick={() => handleDownloadAndRegenerate('PNG')}>PNG</a>
              <a href="#" onClick={() => handleDownloadNpz()}>Coordinates in Numerical Format (NPZ)</a>
              <a href="#" onClick={() => handleDownloadLog()}>Logfile</a>
            </div>
          </div>
        </>
      )}
      {!fromTimeString && !interactiveTooLarge && (<button id={isInteractive ? "mod-cords-btn" : "mod-cords-active"} onClick={handleRegenPlot}>Regenerate Plot</button>)}
      {!showSecondPlot && !interactiveTooLarge && (
      <button id={isInteractive ? "mod-cords-active" : "mod-cords-btn"} onClick={() => setIsInteractive(!isInteractive)}>Modify Mapping</button>
      )}
    </div>
    
      )}
      {/* Interactive START */}
      {isInteractive && (<span>Click and drag on a node to change its position. Once satisfied, please click "Regenerate Plot" above. To exit without saving, click "Modify Mapping".</span>
      )}
      <div id="interactiveDiv" ref={interactiveRef} className={isInteractive ? "visible" : "hidden"}>
        <Interactive timeString={timeString} counter={counter} baseUrl={baseUrl}
        setInteractiveTooLarge={setInteractiveTooLarge} interactiveTooLarge={interactiveTooLarge} getDimensions={getDimensions}
        isInteractive={isInteractive}/>
      </div>
      {/* {isInteractive && (<div><div>&nbsp;</div><div>&nbsp;</div><div>&nbsp;</div></div>
      )} */}
      {/* Interactive END */}

      {showDocumentation && <Documentation />}
      {!showDocumentation && !isLoading && imageUrl && !isInteractive && (
        
        <div className="image-and-legend-container">
                <LogPopup 
        isVisible={showLogPopup} 
        text={logText} 
        onClose={() => setShowLogPopup(false)} 
      />
      <LogPopup 
        isVisible={showLogPopup2} 
        text={logText2} 
        onClose={() => setShowLogPopup2(false)} 
      />
        <div className="image-container">
        <div className="transform-wrapper-container">
          <TransformWrapper 
            ref={transformWrapperRef} 
            minScale={0.1}
            maxScale={5}
            defaultPositionX={bounds.boundX}
            defaultPositionY={bounds.boundY}
            >
               {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                 <React.Fragment>
                 <TransformComponent
                      wrapperStyle={wrapperStyle}>
                   <img src={imageUrl} alt="RNA Landscape" onLoad={onImageLoad} className="img-responsive" style={{ transform: `rotate(${rotation}deg)` }} />
                 </TransformComponent>
                 </React.Fragment>
      )}
          </TransformWrapper>
                
          {showSecondPlot !== false && (
            <TransformWrapper 
            ref={transformWrapperRef2} 
            minScale={0.1}
            maxScale={5}
            defaultPositionX={bounds.boundX}
            defaultPositionY={bounds.boundY}
            >
               {({ zoomIn2, zoomOut2, resetTransform2, ...rest }) => (
                 <React.Fragment>
                 <TransformComponent
                      wrapperStyle={wrapperStyle}>
                   <img src={imageUrl2} alt="RNA Landscape" onLoad={onImageLoad} className="img-responsive" style={{ transform: `rotate(${rotation2}deg)` }} />
                 </TransformComponent>
                 </React.Fragment>
                )}
            </TransformWrapper>
          )}
        </div>
          {showSecondPlot !== false && (
            <div className="controls">
            <p><b>Right Plot:</b></p>
            <button onClick={zoomIn2}>Zoom In</button>
            <button onClick={zoomOut2}>Zoom Out</button>
            <button onClick={centerImage2}>Center</button>
            <button onClick={resetTransform2}>Reset</button>
            <label>Rotate Image:</label>
            <input 
              type="range" 
              min="0" 
              max="360" 
              value={rotation2 + sumRotation2} 
              onChange={handleRotationChange2}
            />
            <input
                type="number"
                min="0"
                max="360"
                value={rotation2 + sumRotation2}
                onChange={handleRotationChange2}
                style={{ marginLeft: '0px', width: '50px' }} // Adjust style as needed
              />
              <button type="button" onClick={(e) => handleShowLog2(e)}>Log</button>

              <div className="dropdown">
                <button type="button" className="dropbtn">Download</button>
                <div className="dropdown-content">
                  <a href="#" onClick={() => handleDownloadAndRegenerate2('SVG')}>SVG</a>
                  <a href="#" onClick={() => handleDownloadAndRegenerate2('PNG')}>PNG</a>
                  <a href="#" onClick={() => handleDownloadNpz()}>Geometrically Mapped Points (NPZ)</a>
                  <a href="#" onClick={() => handleDownloadLog2()}>Logfile</a>
                </div>
              </div>

              <button onClick={() => handleExitSecondPlot()}>Exit Comparison View</button>
            </div>
          )}
          </div>

          {uploadBasePairAnnotation !== "saenger" && uploadBasePairAnnotation !== "none" && showSecondPlot !== true && (
              <div className="img-and-text-container">
              <img 
                src={getImageSrc()}
                alt="Legend"
                className="img-legend"
              />
              <div className="legend-text">
                {uploadBasePairAnnotation === "dssrLw" && <div><b>c</b>: cis<br/><b>t</b>: trans<br/><b>W</b>: Watson-Crick<br/><b>H</b>: Hoogsteen<br/><b>S</b>: Sugar</div>}
                {uploadBasePairAnnotation === "dssr" && <div><b>c</b>: cis<br/><b>t</b>: trans<br/><b>M</b>: major groove<br/><b>m</b>: minor groove<br/><b>W</b>: Watson-Crick</div>}
                {/* Add other conditions and text as needed */}
              </div>
            </div>
          )}

        </div>
      )}
      </div>
      <footer className="app-footer">
      {/*<p><b>If you use the RNAscape webserver, please cite <a href="https://doi.org/10.1093/nar/gkae269"><i>Mitra<sup>†</sup>, Cohen<sup>†</sup>, and Rohs; &nbsp;Nucleic Acids Research, 2024</i></a></b>.</p>*/}
      <p><b>If you use RNAscape, please cite <a href="https://doi.org/10.1093/nar/gkae269"><i>R. Mitra, A.S. Cohen, and R. Rohs. RNAscape: geometric mapping and customizable visualization of RNA structure. Nucleic Acids Res. 2024</i></a></b>.</p>
      <p>RNAscape is maintained by <a target="_blank" href="https://www.rohslab.org/">The Rohs Lab</a> @ University of Southern California. It is free to use by anyone, <b>including commercial users</b>.</p>
      
    </footer>
    </div>
  );
}

export default () => (
  <Router>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </Router>
);
