// Documentation.js
import './documentation.css';

import React from 'react';
// Import any additional dependencies or styles here
// It outputs highly customizable images that provide insight into RNA tertiary structure.

function Documentation() {

  function scrollToSection(event) {
    event.preventDefault();
    const sectionId = event.target.getAttribute('href').substring(1);
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  }
  
  return (
  <div className="documentation-container">
      <div className="sidebar">
          <h3>Documentation</h3>
          <a href="#quickstart" onClick={scrollToSection}>Quickstart</a>
          <a href="#uploading-data" onClick={scrollToSection}> Uploading Data</a>
          <a href="#modifying-coordinates" onClick={scrollToSection}> Modifying a Mapping</a>
          <a href="#visualizing-data" onClick={scrollToSection}>Visualizing Data</a>
          <a href="#saving-output" onClick={scrollToSection}>Saving Output</a>
          <a href="#processing-settings" onClick={scrollToSection}>Processing Settings</a>
          <a href="#visual-settings" onClick={scrollToSection}>Visual Settings</a>
          <a href="#bp-annotation" onClick={scrollToSection}>Base Pair Annotation</a>
      </div>
      <div className="documentation">
        <h3 class="centered-h3"><b>RNAscape</b> is a tool for the geometric mapping and customizable visualization of RNA structure.</h3>
        <img class="doc-image" src = "/3zp8.png" alt="Example Output 3zp8"/>
        <h2>Documentation</h2>
        <h3 id="quickstart" class="left-h3">Quickstart</h3>
        <p>To get started, click <b>Run on Example Data</b> in the website header. This runs RNAscape on PDB structure <a href="https://www.rcsb.org/structure/3ZP8">3zp8</a> using your selected settings.</p>
        <p>You can also enter a PDB ID and click <b>Run</b> to run RNAscape on the selected PDB ID.</p>
        <p>Lastly, you can click <a href="/3zp8-assembly1.cif" download>Example Input</a> here to download an example input data file.</p>
        <h3 id="uploading-data" class="left-h3">Uploading Data</h3>
        <p>RNAscape supports <b>mmCIF or PDB format file uploads</b>. The max file size is <b>50 MB</b>.</p>
        <p>Files greater than <b>7 MB</b> will be processed <b>asynchronously</b>. Please note the <b>unique link</b> that is displayed. The structure will be available for between 24 and 48 hours.</p>
        <img class="doc-image" src="/upload_file_edited.png" alt="Upload file box"/>
        <p>To load a file for processing, click <b>Browse</b> or drag the file into the browse input box. This may appear differently depending on your operating system and web browser.</p>
        <p>Click <b>Run</b> to generate the 2D visualization for a loaded file or PDB ID.</p>
        
        <h3 id="modifying-coordinates" class="left-h3">Modifying Mapping</h3>
        <p>After a structure is visualized, click the <b>Modify Mapping</b> button to enter an interactive mode, where you can move nodes on the graph.</p>
        <p>Simply click and drag on a node to move it. After you are satisfied with your changes, click <b>Regenerate Plot</b> to generate a new image with the updated coordinates. Any Visual Settings changes, including rotation, will be applied along with the updated positions.</p>
        <p>Click <b>Modify Mapping</b> to exit the view (without saving changes) and return to the output image.</p>
        <p>Use the <b>Zoom In</b>, <b>Zoom Out</b>, and <b>Center & Reset Zoom</b> buttons to adjust the interactive graph accordingly. You can also scroll to zoom, and click and drag the white space to pan.</p>
        <p>Hover over a node to see its name, position, and chain.</p>
        <p>Please note this view is only available for structures under 7 MB.</p>

        <h3 id="visualizing-data" class="left-h3">Visualizing Data</h3>
        <p>
          <b>5'</b> to <b>3'</b> polarity is indicated by the default blue arrows (<span style={{color: '#4169E1'}}>→</span>) between nucleotides.
        </p>
        <img class="doc-image" src = "/zoom_controls.png" alt="Zoom Controls"/>
        <p>After a structure is visualized or regenerated, you can manipulate the visualization using:</p>
        <p className="indented">The <b>Rotate Image</b> slider, which alters the rotation of the structure. Simply click <b>Regenerate Plot</b> afterwards to regenerate the graph and text labels in the new orientation.</p>
        <p className="indented">The zoom tools, which allow a user to <b>Zoom In</b>, <b>Zoom Out</b>, <b>Center</b> the graph, and to <b>Reset</b> any previous zooming/panning.</p>
        <p>You can also <b>scroll</b> over the image to Zoom In or Zoom Out.</p>
        <p>To <b>pan</b>, click the image and drag with your mouse. This works especially well to visualize parts of larger structures.</p>
        <p><b>Non-Watson-Crick (non-WC)</b> nucleotides are by default white on the plot. Click <b>Log</b> to see more information about them including their DSSR IDs.</p>
        <h3 id="saving-output" class="left-h3">Saving Output</h3>
        <img class="doc-image" src = "/download.png" alt="Download Dropdown"/>
        <p>Click the <b>Download</b> button to download the RNAscape output image. You can download an <b>SVG</b> or <b>PNG</b>.</p>
        <p>If the image is rotated, RNAscape will generate a rotated PNG or SVG. However, as a best practice, we recommend clicking <b>Regenerate Plot</b> to reprocess and optimize the image.</p>
        <p>Advanced users can click <b>Download</b> and <b>Coordinates in Numerical Format (NPZ)</b> to download the RNAscape backend output as an <b>NPZ</b> file, which can be opened in Python. This includes the mapped 2D positions for each nucleotide.</p>
        <p>Users can download the <b>Log</b> by clicking <b>Download</b> and <b>Logfile</b>.</p>
        <p>Lastly, for applicable structures, users can interactively <b>Modify Coordinates</b> of the graph as described above.</p>


        <h3 id="processing-settings" class="left-h3">Processing Settings</h3>
        <p><b>Processing settings</b> affect the geometric mapping (nucleotide positioning). Therefore, please click <b>Run</b> (to run the full pipeline) in order to change them for an existing plot.</p>
        <img class="doc-image" src = "/processing.png" alt="Processing Settings"/>
        <p>If <b>Ladder Orientation Post-Processing</b> is checked, projected helical ladders within 30° and 20 Å are merged.</p>
        <p>By default, RNAscape <b>bulges</b> out loops to reduce crowding. Setting <b>Bulge Out Loops</b> to <b>Conditional</b> reduces the size of the graph, but may create more RNA overlap.</p>
        <div className="side-by-side-images">
          <img className="side-image" src="/3zp8.png" alt="Always Loop Bulging"/>
          <img className="side-image" src="/3zp8-conditional.png" alt="Conditional Loop Bulging"/>
        </div>
        <p>The left image depicts <b>Always Bulging</b>. Notice the additional space in its right helical ladder.</p>
        <p>The right image depicts <b>Conditional Bulging</b>. We recommend experimenting to see what looks best for a given structure.</p>

        <h3 id="visual-settings" class="left-h3">Visual Settings</h3>
        <p>There are four Visual Settings categories: Nucleotide Settings, Number Settings, Add Second Plot, and Color Settings.</p>
        <p>After changing visual settings for an existing plot, simply click <b>Regenerate Plot</b> to view them. This avoids re-running the geometric mapping pipeline and is therefore faster.</p>

        <img class="doc-image" src = "/nucleotide_settings.png" alt="Nucleotide Settings"/>
        <p><b>Base Pair Annotation</b> changes how non-WC base pairing is notated. It is discussed in detail below. Please note that it can be turned off altogether by selecting <b>None</b>.</p>
        <p><b>Arrow Size</b> modifies how large the arrows are between nucleotides (on each chain). These arrows can be turned off altogether by setting it to 0, and the default is 1.</p>
        <p><b>Circle Size</b> modifies how large the circles are surrounding each nucleotide. They can be turned off altogether by setting it to 0 (seen below). The default is 1.</p>
        <img class="doc-image" src="/no_circle_output.svg" alt="No Circle Output"/>
        <p><b>Circle Label Size</b> modifies how large the text is for each nucleotide. The default is 1.</p>
        <p><b>Marker Size</b> modifies how large the base pair annotation markers are. The default is 1.</p>
        <img class="doc-image" src="/number_settings.png" alt="Residue Numbers Checkbox and Settings"/>
        <p>Check/uncheck <b>Show Residue Numbers</b> to turn on/off residue numbers. If checked, four additional options are shown:</p>
        <p className="indented"><b>Frequency</b> modifies how often number labels are shown for each chain. The default is every 10.</p>
        <p className="indented"><b>Number Label Size</b> modifies how large the number labels are. The default is 1.</p>
        <p className="indented"><b>Number Distance</b> changes how far away the number labels are from their corresponding nucleotides. The default is 1.</p>
        <p className="indented"><b>Color</b> changes the color of number labels.</p>
        
        <img class="doc-image" src="/second_plot_settings.png" alt="Add Second Plot Settings"/>
        <p className="indented">RNAscape natively supports the <b>side-by-side comparison</b> of two plots.</p>
        <p className="indented"><b>After the first structure is visualized</b>, simply click Visual Settings and either select the PDB ID or upload the second structure.</p>
        <p className="indented">Two sets of plot controls will be shown. The first structure is on the left (top controls), and the second structure is on the right (bottom controls).</p>
        <img class="doc-image" src="/side_plots.png" alt="Comparison of Two Plots"/>
        <p className="indented">To exit this view, click <b>Exit Comparison View</b> in the bottom plot controls.</p>


        <img class="doc-image" src = "/color_settings.png" alt="Color Settings"/>
        <p>In Color Settings, you can <b>customize the color</b> of each nucleotide type and other features, either by clicking on the color box, or by inputting a custom hex value. 'X' refers to a non-standard nucleotide.</p>
        <p>Depending on your operating system and web browser, the color selector box may appear differently than the ones depicted above.</p>
        <p className="indented"><b>Chain</b> refers to the nucleic acid backbone, denoted in the 5' to 3' direction.</p>
        <p className="indented"><b>Pairing</b> refers to base pairs, denoted by dotted lines.</p>
        <p className="indented"><b>Marker</b> refers to base pair annotation symbols, denoted by circles, squares, and trianges on the pairing.</p>
        
        <h3 id="bp-annotation" class="left-h3">Base Pair Annotation</h3>
        <p>RNAscape supports three <b>Base Pair Annotation</b> styles, which can be changed in <b>Advanced Settings</b>: Leontis-Westhof, Saenger, and DSSR. You can also select None to remove them altogether.</p>
        <p>The definitions surrounding base pairing can be ambiguous in some cases. All base pairing is calculated via X3DNA-DSSR, which can differ from other methods.</p>
        <p>For the Saenger Base Pair Annotation, there are 28 possible base pair types. For this reason, no legend is provided.</p>
        <p>The Leontis-Westhof Base Pair Annotation Legend has two main parameters:</p>
        <p className="indented"><b>c/t</b> refer to a cis or trans bond orientation respectively.</p>
        <p className="indented"><b>W/H/S</b> refer to a Watson-Crick, Hoogsteen, or Sugar edge respectively.</p>
        <p>You can find more information regarding Saenger and Leontis-Westhof Base Pair annotations <a href="https://beta.nakb.org/basics/bases.html">here</a>.</p>
        <p>The DSSR Base Pair Annotation Legend has two main parameters:</p>
        <p className="indented"><b>c/t</b> refer to a cis or trans bond orientation respectively.</p>
        <p className="indented"><b>M/m/W</b> refer to major groove, minor groove, or Watson-Crick edge respectively.</p>
        <p>&nbsp;</p>
      </div>
  </div>
  );
}

export default Documentation;
